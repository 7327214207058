*, :before, :after {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
}

html, body {
  color: #f5f5f5;
  text-align: center;
  background: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.txt {
  text-shadow: 0 1px 5px #fffc;
  width: 404px;
  animation: 5s forwards fadeIn;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  letter-spacing: .3rem;
  font-family: Jura, sans-serif;
  font-size: 7.5rem;
  font-weight: 400;
  transition: opacity 2s;
  top: 33%;
}

p {
  letter-spacing: .15rem;
  opacity: 0;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Comfortaa, sans-serif;
  font-size: 1rem;
}

@media screen and (width <= 1023px) {
  p {
    margin-top: 2rem;
  }
}

p:first-of-type {
  animation-delay: 1.5s;
  top: 50%;
}

p:last-of-type {
  animation-delay: 2.5s;
  top: 53%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .9;
  }
}
/*# sourceMappingURL=404.86e45c45.css.map */
